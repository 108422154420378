import { reduce } from "lodash-es"
import { i18n } from "@/config"

export const FIXED_FEE_TYPE = "fixed"
export const PERIOD_FEE_TYPE = "period"
export const DAY_FEE_TYPE = "day"

export const EXPLANATION_CAPTION_MAX_LENGTH = 128

export const feeTypes = () => {
  return [
    {
      name: i18n.t("company_system.basic_settings.options.options_form.fixed_fee"),
      type: FIXED_FEE_TYPE
    },
    {
      name: i18n.t("company_system.basic_settings.options.options_form.period_fee"),
      type: PERIOD_FEE_TYPE
    },
    {
      name: i18n.t("company_system.basic_settings.options.options_form.price_per_day_fee"),
      type: DAY_FEE_TYPE
    }
  ]
}

export const feeTypeNames = () => {
  return reduce(
    feeTypes(),
    (obj, { type, name }) => {
      obj[type] = name
      return obj
    },
    {}
  )
}
